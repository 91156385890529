<template>
  <component
    :is="dynamicComponent"
    v-if="dynamicComponent"
    v-bind="$attrs"
    :loc-slug="locSlug"
  />
</template>

<script>
import { mapGetters } from 'vuex'

import Layout from '@utils/get-layout'

export default {
  name: 'LocationViewLoader',
  components: {
    Layout,
  },
  props: {
    locSlug: {
      type: String,
      required: true,
      default: 'default',
    },
  },
  data() {
    return {
      dynamicComponent: null,
      resolvedPath: '',
    }
  },
  computed: {
    ...mapGetters('site', ['getThemeName']),
    customThemePageLoader() {
      if (!this.getThemeName.length) {
        return null
      }
      return () => import(`@themes/${this.customViewPath}`)
    },
    baseThemeLoader() {
      if (!this.getThemeName.length) {
        return null
      }
      return () => import(`@restoBaseTheme/${this.customViewPath}`)
    },
    defaultSingleLocation() {
      return () => import('@views/single-location')
    },
    customViewPath() {
      return `${this.getThemeName}/views/single-location`
    }, // customViewPath()
  },
  mounted() {
    this.$store.dispatch('path/setPathEditability', true)
    this.customThemePageLoader()
      .then(() => {
        this.dynamicComponent = () => this.customThemePageLoader()
        this.resolvedPath = `@themes/${this.customViewPath}`
      })
      .catch(() => {
        this.baseThemeLoader()
          .then(() => {
            this.dynamicComponent = () => this.baseThemeLoader()
            this.resolvedPath = `@restoBaseTheme/${this.customViewPath}`
          })
          .catch(() => {
            this.defaultSingleLocation()
              .then(() => {
                this.dynamicComponent = () => this.defaultSingleLocation()
                this.resolvedPath = '@views/single-location'
              })
              .catch(() => {
                this.dynamicComponent = () =>
                  import('@restoBaseTheme/views/page-default')
                this.resolvedPath = '@restoBaseTheme/views/page-default'
              })
          })
      })
  },
}
</script>
